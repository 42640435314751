<template>
  <div class="recommend">
    <sideBar :topHeight="515"></sideBar>
    <div class="header">
      <Header :headSelectIndex='3'></Header>
    </div>
    <div class="banner">
      <div>
        <img src="../assets/recommend_img320.png" alt="">
        <div class="word">
          <div>找律师 上律兜</div>
          <div>平台对律师身份<span>严格验证</span>，执业信息<span>真实可靠</span></div>
        </div>
      </div>

    </div>
    <div class="title">今日推荐</div>
    <div class="today">
      <div class="left" v-if="today.length>0" @click="godetail(today[slideActive].LawyerID,'今日推荐律师')">
        <div class="grade">
          <div>
            <img :src="today[slideActive].icon" alt="">
          </div>
          <span>{{today[slideActive].LawyerLevel}}</span>
        </div>
        <div class="left">
          <img :src="today[slideActive].LawyerPic" alt="">
          <div>近30天</div>
          <div><img src="../assets/icon_serve.png" alt="">服务 {{today[slideActive].ServiceCountMonth}} 人</div>
          <div><img src="../assets/icon_evaluate.png" alt="">好评率 {{today[slideActive].Satisfaction}}</div>
          <div>咨询</div>
        </div>
        <div class="right">
          <div class="name"><span>{{today[slideActive].LawyerName}}</span>执业{{today[slideActive].LawyerPracticeYears}}年
          </div>
          <div class="good">擅长领域：{{today[slideActive].ProfessionalField}}</div>
          <div class="serve">
            <div class="item">
              <div>私人律师</div>
              <div>￥{{today[slideActive].productInfoLst[0].ProductPrice}}/月</div>
              <img src="../assets/icon_recommend14.png" alt="">
            </div>
            <div class="item">
              <div>图文咨询</div>
              <div>￥{{today[slideActive].productInfoLst[1].ProductPrice}}/次</div>
            </div>
            <div class="item">
              <div>电话咨询</div>
              <div>￥{{today[slideActive].productInfoLst[2].ProductPrice}}/次</div>
            </div>
            <div class="item">
              <div>···</div>
            </div>
          </div>
          <div class="pj">
            <div class="pjtitle">最新评价</div>
            <div class="pjtop" v-if="today[slideActive].LatestComment!=null">
              <div class="left">
                <span>{{today[slideActive].LatestComment.MemberMoblie_kh}}</span>
                <div class="star">
                  <img src="../assets/icon_shape_selected.png" alt=""
                    v-for="index in today[slideActive].LatestComment.Stars">
                  <img src="../assets/icon_shape_normal.png" alt=""
                    v-for="index in 5-today[slideActive].LatestComment.Stars">
                </div>
              </div>
              <div class="right">{{today[slideActive].LatestComment.CreateDate}}</div>
            </div>
            <div class="pjcontent" v-if="today[slideActive].LatestComment!=null">
              {{today[slideActive].LatestComment.CommentDes}}</div>
            <div class="nopj" v-else>暂无评价</div>
          </div>
        </div>
      </div>
      <div class="right" @mouseover="showNavFun" @mouseleave="hideNavFun">
        <div class="swiper">
          <div class="swiper-wrapper">
            <div class="swiper-slide" :class="slideActive==index?'active':''" v-for="(item,index) in today">
              <div class="slide_in">
                <img :src="item.LawyerPic" alt="">
                <div class="slide_item">
                  <div>{{item.LawyerName}}</div>
                  <div>{{item.LawyerLevel}}律师 | 执业{{item.LawyerPracticeYears}}年</div>
                </div>
              </div>
              <div class="slide_modal" :data-index="index"></div>
            </div>
          </div>
        </div>
        <div class="swiper-button-prev" v-show="showNav"><img src="../assets/icon_arrows_up.png" alt=""></div>
        <div class="swiper-button-next" v-show="showNav"><img src="../assets/icon_arrows_down.png" alt=""></div>
      </div>
    </div>
    <div class="title">精选律师</div>
    <div class="tab">
      <div>
        <div class="tab_item" :class="tab==0?'active':''" @click="changeTab(0)">
          <img src="../assets/tab_icon_marriage.png" alt="">家庭婚姻
        </div>
        <div class="tab_item" :class="tab==1?'active':''" @click="changeTab(1)">
          <img src="../assets/tab_iocn_traffic.png" alt="">交通事故
        </div>
        <div class="tab_item" :class="tab==2?'active':''" @click="changeTab(2)">
          <img src="../assets/tab_icon_contract.png" alt="">合同欠款
        </div>
      </div>

    </div>
    <div class="choice" v-if="jxList.length>0">
      <div class="item" v-for="(item,index) in jxList" @click="godetail(item.LawyerGuid,'精选律师')">
        <img :src="item.LawyerPic" alt="">
        <div class="info">
          <div class="name"><span>{{item.LawyerName.substring(0,1)}}律师</span>执业{{item.LawyerPracticeYears}}年<div>
              {{item.Level}}律师</div>
          </div>
          <div class="three">近30天：<div>服务
              <span>{{item.ServiceCountMonth==0?parseInt(Math.random()*100+100):item.ServiceCountMonth}}</span> 人</div>
            <div>好评率 <span>{{item.Satisfaction=='0%'?'100%':item.Satisfaction}}</span></div>
          </div>
          <div class="word">累计解决<div>{{item.OrderNum}}件{{jxLawyer[tab].QTypeName}}</div>纠纷</div>
          <div class="btn">咨询</div>
        </div>
      </div>
    </div>
    <div class="nochoice" v-else>暂无此类精选律师</div>
    <div class="title" style="padding-top:19px;">热门律师</div>
    <div class="choice hot">
      <div class="item" v-for="(item,index) in hotList" @click="godetail(item.MemberGuid,'热门律师')">
        <img :src="item.MemberPersonalPic" alt="">
        <div class="info">
          <div class="name"><span>{{item.MemberRealName.substring(0,1)}}律师</span>执业{{item.LawyerPracticeYears}}年<div>
              {{item.Level}}律师</div>
          </div>
          <div class="three">近30天：<div>服务
              <span>{{item.ServiceCountMonth==0?parseInt(Math.random()*100+100):item.ServiceCountMonth}}</span> 人</div>
            <div>好评率 <span>{{item.Satisfaction=='0%'?'100%':item.Satisfaction}}</span></div>
          </div>
          <div class="good">
            <div v-for="it in item.profession">{{it}}</div>
          </div>
          <div class="btn">咨询</div>
        </div>
      </div>
    </div>
    <div class="nochoice" v-if="hotList.length==0">暂无热门律师</div>
    <div class="more" @click="goother">更多律师，请扫描 <span @mouseover="showCodeFun"
        @mouseleave="hideCodeFun">律兜法律咨询小程序</span> 或下载 <span @mouseover="showDownFun"
        @mouseleave="hideDownFun">律兜法律咨询APP</span>
      <div class="code" v-if="showCode"><img src="../assets/ilvdo.jpg" alt=""></div>
      <div class="code code1" v-if="showDown"><img src="../assets/download.png" alt=""></div>
    </div>
    <FootBox></FootBox>
  </div>
</template>
<script>
  import {
    GetDayLawyerRecommend,
    GetChoiceLawyers,
    GetPopularLawyer
  } from '@/api/index'
  import Header from '@/components/header'
  import FootBox from '@/components/FootBox'
  import sideBar from '@/components/sideBar'
  import Swiper from "swiper"
  var mySwiper, timer;
  export default {
    name: 'recommend',
    data() {
      return {
        menu: '律师推荐',
        slideActive: 0,
        total: 0,
        tab: 0,
        showCode: false,
        showDown: false,
        showNav: false,
        today: [],
        jxLawyer: [],
        jxList: [],
        hotList: []
      }
    },
    mounted() {
      var that = this
      var loading = this.$loading();
      GetDayLawyerRecommend({}).then(res => {
        console.log(res)
        if (res.state == 0) {
          for (var i = 0; i < res.data.length; i++) {
            res.data[i].LawyerName = res.data[i].LawyerName.substring(0, 1) + '律师'
            if (res.data[i].ServiceCountMonth == 0) {
              res.data[i].ServiceCountMonth = parseInt(Math.random() * 100 + 100)
            }
            if (res.data[i].Satisfaction == '0%') {
              res.data[i].Satisfaction = '100%'
            }
            var icon = ''
            switch (res.data[i].LawyerLevel) {
              case '新锐':
                icon = require('../assets/icon_xinrui24.png')
                break;
              case '才俊':
                icon = require('../assets/icon_caijun24.png')
                break;
              case '中坚':
                icon = require('../assets/icon_zhongjian24.png')
                break;
              case '明星':
                icon = require('../assets/icon_mingxin24.png')
                break;
            }
            res.data[i].icon = icon
          }
          this.today = res.data
          this.total = res.data.length
          mySwiper = new Swiper(".swiper", {
            slidesPerView: 3,
            direction: 'vertical',
            spaceBetween: 10,
            initialSlide: 0,
            observer: true, //修改swiper自己或子元素时，自动初始化swiper  
            observeParents: true, //修改swiper的父元素时，自动初始化swiper  
            navigation: {
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            },
            on: {
              init(e) {
                loading.close();
                setTimeout(() => {
                  mySwiper.slideTo(that.slideActive, 1000)
                }, 50)
                timer = setInterval(function () {
                  if (that.slideActive < that.total - 1) {
                    that.slideActive = that.slideActive + 1
                  } else {
                    that.slideActive = 0
                  }
                  mySwiper.slideTo(that.slideActive, 1000)
                }, 10000)
              },
              click(e) {
                console.log(e.target.dataset.index)
                that.slideActive = Number(e.target.dataset.index)
                clearInterval(timer)
                timer = setInterval(function () {
                  if (that.slideActive < that.total - 1) {
                    that.slideActive = that.slideActive + 1
                  } else {
                    that.slideActive = 0
                  }
                  mySwiper.slideTo(that.slideActive, 1000)
                }, 10000)
              }
            }
          })
        }
      })
      this.getJX()
      GetPopularLawyer().then(res => {
        if (res.state == 0) {
          for (var i = 0; i < res.data.length; i++) {
            res.data[i].profession = res.data[i].ProfessionalField.split(' ')
          }
          this.hotList = res.data
        }
      })
      _hmt.push(['_trackPageview', '/recommend'])
    },
    methods: {
      getJX() {
        GetChoiceLawyers().then(res => {
          if (res.state == 0) {
            this.jxLawyer = res.data
            this.jxList = this.jxLawyer[0].LawyerParamList
          }
        })
      },
      godetail(guid, title) {
        var name = null;
        if (this.tab == 0) {
          name = '家庭婚姻律师'
        } else if (this.tab == 1) {
          name = '交通事故律师'
        } else {
          name = '合同欠款律师'
        }
        _hmt.push(['_trackEvent', 'click', title + '-' + name, 'pc']);
        this.$router.push({
          path: '/lawyer?lawyerguid=' + guid
        })
      },
      changeTab(num) {
        this.tab = num
        this.jxList = this.jxLawyer[num].LawyerParamList
        let title = '';
        if (num == 0) {
          title = '精选律师-家庭婚姻'
        }
        if (num == 1) {
          title = '精选律师-交通事故'
        }
        if (num == 2) {
          title = '精选律师-合同欠款'
        }
        _hmt.push(['_trackEvent', 'click', title, 'pc']);
      },
      goother() {
        _hmt.push(['_trackEvent', 'click', '下载客户端按钮', 'pc']);
        this.$router.push({
          path: '/download'
        })
      },
      showCodeFun() {
        this.showCode = true
      },
      hideCodeFun() {
        this.showCode = false
      },
      showDownFun() {
        this.showDown = true
      },
      hideDownFun() {
        this.showDown = false
      },
      showNavFun() {
        this.showNav = true
      },
      hideNavFun() {
        this.showNav = false
      },
    },
    components: {
      Header,
      FootBox,
      sideBar
    }
  }

</script>

<style scoped lang="scss">
  .header {
    width: 100%;
    min-width: 940px;
    background: #21D0C8;
    height: 150px;
  }

  .banner {
    width: 100%;
    height: 320px;
    background: #DEE2EF;

    >div {
      position: relative;
      width: 940px;
      margin: 0 auto;

      img {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
      }

      .word {
        position: absolute;
        top: 90px;
        left: 0;

        div:nth-child(1) {
          font-size: 46px;
          font-weight: bold;
          color: #303654;
          margin-bottom: 10px;
        }

        div:nth-child(2) {
          font-size: 20px;
          color: #303654;

          span {
            color: #4D70E9;
          }
        }
      }
    }
  }

  .title {
    padding: 39px 0 30px;
    text-align: center;
    font-size: 30px;
    font-weight: bold;
    color: #000000;
  }

  .today {
    width: 940px;
    height: 350px;
    margin: 0 auto;

    >.left {
      width: 560px;
      height: 307px;
      background: linear-gradient(25deg, #0B0F22 0%, #303654 100%);
      border-radius: 14px;
      float: left;
      padding: 43px 40px 0;
      position: relative;
      cursor: pointer;

      .grade {
        position: absolute;
        top: 0;
        right: 0;
        width: 110px;
        height: 36px;
        background: #0A0E22;
        border-radius: 0px 14px 0px 18px;

        >div {
          width: 54px;
          height: 36px;
          background: #FFFFFF;
          border-radius: 0px 18px 18px 18px;
          display: inline-block;

          img {
            display: block;
            padding: 5px 12px;
          }
        }

        span {
          font-size: 18px;
          color: #FFFFFF;
          position: absolute;
          top: 6px;
          right: 12px;
        }
      }

      >.left {
        float: left;
        font-size: 16px;
        color: #FFFFFF;

        >img:nth-child(1) {
          width: 112px;
          height: 112px;
          background: #FFFFFF;
          border: 4px solid #FFFFFF;
          border-radius: 50%;
          margin-bottom: 10px;
        }

        div:nth-child(2) {
          margin-bottom: 10px;
        }

        div:nth-child(4),
        div:nth-child(3) {
          margin-bottom: 5px;

          img {
            margin-right: 10px;
            vertical-align: middle;
          }
        }

        div:nth-child(5) {
          margin-top: 23px;
          width: 120px;
          height: 30px;
          background: #0B9AFB;
          border-radius: 6px;
          text-align: center;
          line-height: 30px;
        }
      }

      >.right {
        float: right;
        width: 380px;
        font-size: 18px;
        color: #FFFFFF;

        .name {
          margin-bottom: 10px;

          span {
            font-size: 30px;
            font-weight: bold;
            margin-right: 11px;
          }
        }

        .good {
          margin-bottom: 35px;
        }

        .serve {
          margin-bottom: 38px;

          .item {
            width: 78px;
            height: 44px;
            background: #FFFFFF;
            border-radius: 6px;
            position: relative;
            padding: 6px 0 0 11px;
            display: inline-block;
            margin-right: 8px;
            vertical-align: middle;

            img {
              position: absolute;
              top: -5px;
              right: -4px;
            }

            font-size: 14px;

            div:nth-child(1) {
              color: #333333;
              font-weight: 500;
            }

            div:nth-child(2) {
              color: #F24537;
            }
          }

          .item:last-child {
            margin: 0;
            width: 89px;
            height: 50px;
            padding: 0;
            text-align: center;
            line-height: 50px;

            >div {
              font-size: 25px;
            }
          }
        }

        .pj {
          font-size: 16px;
          color: #FFFFFF;

          .pjtitle {
            margin-bottom: 10px;
          }

          .pjtop {
            font-size: 14px;
            height: 22px;

            .left {
              float: left;

              .star {
                margin-left: 9px;
                display: inline-block;
                vertical-align: middle;
                position: relative;
                top: -4px;

                img {
                  margin-right: 3px;
                  vertical-align: middle;
                }
              }
            }

            .right {
              float: right;
            }
          }

          .pjcontent {
            width: 380px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            word-break: break-all;
          }

          .nopj {
            text-align: center;
            margin: 30px;
          }
        }
      }
    }

    >.right {
      width: 230px;
      height: 290px;
      background: #F3F3F3;
      border-radius: 14px;
      float: right;
      padding: 30px 35px;
      position: relative;

      .swiper {
        width: 100%;
        height: 100%;
        overflow: hidden;

        .swiper-slide {
          text-align: center;
          font-size: 18px;
          background: #fff;
          border-radius: 14px;
          /* Center slide text vertically */
          display: -webkit-box;
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          -webkit-justify-content: center;
          justify-content: center;
          -webkit-box-align: center;
          -ms-flex-align: center;
          -webkit-align-items: center;
          align-items: center;
          cursor: pointer;

          .slide_in {
            padding: 15px 17px;
            display: flex;
          }

          .slide_modal {
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;
            z-index: 9;
            width: 100%;
            height: 100%;
          }

          img {
            width: 60px;
            height: 60px;
            border-radius: 50%;
            margin-right: 10px;
          }

          .slide_item {
            flex: 1;
            text-align: left;
            position: relative;
            top: 4px;

            div:nth-child(1) {
              font-size: 20px;
              font-weight: bold;
              color: #333333;
              margin-bottom: 5px;
            }

            div:nth-child(2) {
              font-size: 14px;
              color: #666;
            }
          }
        }

        .swiper-slide.active {
          background: #303654;

          .slide_in .slide_item>div {
            color: #FFFFFF;

          }
        }

      }

      .swiper-button-prev {
        top: 22px;
        left: 50%;
        margin-left: -20px;
        width: 40px;
        height: 20px;
        background: #aaa;
        border-radius: 0px 0 6px 6px;
        cursor: pointer;

        img {
          position: absolute;
          top: 7px;
          left: 13px;
        }
      }

      .swiper-button-next:focus,
      .swiper-button-prev:focus {
        outline: 0;
      }

      .swiper-button-next {
        bottom: 0px;
        left: 50%;
        top: auto;
        margin-left: -20px;
        width: 40px;
        height: 20px;
        background: #aaa;
        border-radius: 6px 6px 0px 0;
        cursor: pointer;

        img {
          position: absolute;
          top: 8px;
          left: 13px;
        }
      }
    }
  }

  .tab {
    box-shadow: 0px 9px 14px 0px rgb(0 0 0 / 8%);
    margin-bottom: 48px;

    >div {
      width: 940px;
      margin: 0 auto;
      display: flex;
    }

    .tab_item {
      flex: 1;
      padding-bottom: 20px;
      border-bottom: 4px solid #fff;
      text-align: center;
      cursor: pointer;

      img {
        vertical-align: middle;
        margin-right: 4px;
      }
    }

    .tab_item.active {
      border-bottom: 4px solid #0B9AFB;
    }
  }


  .choice {
    width: 940px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .item {
      width: 400px;
      height: 96px;
      background: #FFFFFF;
      border: 2px solid #F3F3F3;
      border-radius: 14px;
      padding: 18px 30px 0;
      display: flex;
      margin-bottom: 20px;

      >img {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        margin-right: 10px;
      }

      .info {
        flex: 1;
        position: relative;

        .name {
          font-size: 14px;
          color: #666666;
          margin-bottom: 6px;

          span {
            font-size: 20px;
            font-weight: bold;
            color: #333333;
            margin-right: 8px;
          }

          div {
            padding: 1px 6px;
            background: #303654;
            border-radius: 10px 10px 10px 0px;
            color: #fff;
            display: inline-block;
            position: relative;
            top: -9px;
            margin-left: 5px;
          }
        }

        .three {
          font-size: 13px;
          color: #666666;
          margin-bottom: 11px;

          div {
            color: #000000;
            display: inline-block;

            span {
              color: #FE9A2F;
            }
          }

          div:nth-child(1) {
            margin-right: 20px;
          }
        }

        .word {
          font-size: 13px;
          color: #000000;

          div {
            display: inline-block;
            padding: 1px 4px;
            background: #FE9A2F;
            border-radius: 6px;
            color: #FFFFFF;
            margin: 0 6px;
          }
        }

        .btn {
          width: 80px;
          height: 30px;
          background: #0B9AFB;
          border-radius: 6px;
          font-size: 16px;
          color: #FFFFFF;
          position: absolute;
          right: 0;
          top: 27px;
          text-align: center;
          line-height: 30px;
          cursor: pointer;
        }
      }
    }
  }

  .nochoice {
    text-align: center;
    margin: 100px;
    color: #666;
  }

  .hot {
    .item {
      margin-bottom: 20px;
      height: 102px;

      .good {
        div {
          display: inline-block;
          padding: 3px 8px;
          background: #F3F3F3;
          border-radius: 6px;
          font-size: 14px;
          color: #333333;
          margin-right: 12px;
        }
      }
    }
  }

  .more {
    width: 940px;
    height: 50px;
    background: #F3F3F3;
    border-radius: 14px;
    margin: 30px auto 16px;
    text-align: center;
    line-height: 50px;
    font-size: 16px;
    color: #000000;
    position: relative;

    span {
      font-weight: bold;
      color: #21D0C8;
      cursor: pointer;
    }

    .code {
      position: absolute;
      top: -158px;
      left: 367px;
      width: 150px;
      height: 150px;
      background: #FFFFFF;
      box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.3100);
      border-radius: 10px;
      padding: 6px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .code1 {
      left: 556px;
      padding: 0;
      width: 162px;
      height: 162px;

      img {
        width: 100%;
        height: 100%;
        border-radius: 10px;
      }
    }
  }

</style>
<style>


</style>
